<template>
  <div class="DarkWhite">
    <v-data-table
      :headers="headers"
      :items="risList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search RIS/IAR number"
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            color="green"
            v-model="userID"
            item-text="name"
            item-value="UserID"
            :items="userList"
            dense
            hide-details
            class="mr-2 mt-2"
            style="max-width:200px;"
            label="Filter By User"
          >
          </v-autocomplete>
          <v-autocomplete
            color="green"
            v-model="statusID"
            item-text="description"
            item-value="id"
            :items="statusList"
            dense
            hide-details
            class="mr-2 mt-2"
            style="max-width:200px;"
            label="Filter By Status"
          >
          </v-autocomplete>
        </v-toolbar>
      </template>
      <template v-slot:[`item.FundSource`]="{ item }">
        {{ item.FundCode }} - {{ item.SpecificFundSourceDescription }}
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.totalAmount) }}</span>
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.ApprovalStatus`]="{ item }">
        <span v-if="item.IssuedStatusID == 7 || item.IssuedStatusID == 6">
          <v-tooltip top v-if="item.IssuedStatusID == 7">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green darken-2" v-bind="attrs" v-on="on" >mdi-check-circle</v-icon>
          </template>
            <span>Approved By Supply</span>
          </v-tooltip>
          <v-tooltip top v-else-if="item.IssuedStatusID == 6">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green darken-2" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
          </template>
            <span>Pending By Supply</span>
          </v-tooltip>
        </span>
        <span v-else-if="item.ApprovedStatusID == 7 || item.ApprovedStatusID == 6">
          <v-tooltip top v-if="item.ApprovedStatusID == 7">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue darken-2" v-bind="attrs" v-on="on" >mdi-check-circle</v-icon>
          </template>
            <span>Approved By DAS</span>
          </v-tooltip>
          <v-tooltip top v-else-if="item.ApprovedStatusID == 6">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue darken-2" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
          </template>
            <span>Pending By DAS</span>
          </v-tooltip>
        </span>
        <span v-else-if="item.RequestedStatusID == 7 || item.RequestedStatusID == 6">
          <v-tooltip top v-if="item.RequestedStatusID == 7">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="orange darken-2" v-bind="attrs" v-on="on" >mdi-check-circle</v-icon>
          </template>
            <span>Approved By Requestor</span>
          </v-tooltip>
          <v-tooltip top v-else-if="item.RequestedStatusID == 6">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="orange darken-2" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
          </template>
            <span>Pending By Requestor</span>
          </v-tooltip>
        </span>
        <span v-else>
          <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="grey darken-2" v-bind="attrs" v-on="on" >mdi-timer</v-icon>
          </template>
            <span>For Approval by Requestor</span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:[`item.Other_Actions`]="{ item }">
        <span class="text-no-wrap">
        <span
        class="text-green"
          v-if="item.IsCancelled == 0 && (parseFloat(item.ICSQty) + parseFloat(item.PARQty)) == parseFloat(item.RISQty)"
        >
        <v-icon small color="header">mdi-check</v-icon>
          Dispense
        </span>
        <v-btn
          color="header darken-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="dispenseRIS(item)"
          v-else-if="item.IsCancelled == 0 && (parseFloat(item.ICSQty) + parseFloat(item.PARQty)) < parseFloat(item.RISQty)"
        >
          Dispense
        </v-btn>
        <span class="grey--text" v-else-if="item.IsCancelled == 0 && item.IssuedStatusID != 7" >For Approval</span>
        <span class="red--text" v-else >Cancelled</span>
        </span>
      </template>
      <template v-slot:[`item.IsPrinted`]="{ item }">
        <v-checkbox v-model="item.IsPrinted" color="header" @change="selectPrinted(item)"></v-checkbox>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="viewRIS(item)"
          >
            View
          </v-btn>
          <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="printRIS(item)"
            v-if="item.IsCancelled == 0"
          >
            Print
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogRISType" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Please select RIS type:</v-card-title>
        <v-card-text>
          <v-row justify="center" class="mt-2">
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple</v-icon
              ><br />
              <v-btn color="green darken-2" @click="supply()" text
                >Supply</v-btn
              >
            </v-col>
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple-outline</v-icon
              ><br />
              <v-btn color="green darken-2" @click="accounting()" text
                >Accounting</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogRISType = false"
                >Cancel</v-btn
              >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RISModal :risData="risData" />
    <DispenseModal :processData="dispenseData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    RISModal: () => import("./ViewRIS.vue"),
    DispenseModal: () => import("./DispenseRIS.vue"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    risList: [],
    risData: [],
    dispenseData: [],
    headers: [
      {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'RISPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Fund Source',
          align: 'start',
          sortable: false,
          value: 'FundSource',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'end',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', width: '140', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      dialogRISType: false,
      risType: 1,
      itemToPrint: [],
      statusList: [
        {id: 0, description: "All"},
        {id: 1, description: "Approved"},
        {id: 2, description: "For Approval"},
        {id: 3, description: "Cancelled"},
      ],
      statusID: 0,
      userList: [],
      userID: 0,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    statusID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    userID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  created() {
    if(this.$store.state.user.roleID == 6 || this.$store.state.user.roleID == 15 || this.$store.state.user.roleID == 16 || this.$store.state.user.roleID == 17 || this.$store.state.user.roleID == 20){
      this.headers = [
      {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'RISPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Fund Source',
          align: 'start',
          sortable: false,
          value: 'FundSource',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'end',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: '',
          align: 'center',
          sortable: false,
          value: "ApprovalStatus",
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: "Other_Actions",
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Printed?',
          align: 'center',
          sortable: false,
          value: 'IsPrinted',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', width: '140', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ];
    }
    else{
      this.headers = [
      {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'RISPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Fund Source',
          align: 'start',
          sortable: false,
          value: 'FundSource',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'end',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', width: '140', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ];
    }
  },
  mounted() {
    this.eventHub.$on("closeViewRIS", () => {
      this.initialize();
    });
    this.eventHub.$on("closeDispenseRIS", () => {
      this.initialize();
    });
    this.getUsers();
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewRIS");
    this.eventHub.$off("closeDispenseRIS");
  },
  methods: {
    viewRIS(item) {
      this.risData = item;
    },
    dispenseRIS(item) {
      this.dispenseData = item;
    },
    printRIS(item) {
      this.itemToPrint = item;
      this.dialogRISType = true;
    },
    supply(){
      this.link = this.apiUrl + "/ris/report/" + this.itemToPrint.RISID + "/1";
      window.open(this.link);
    },
    accounting(){
      this.link = this.apiUrl + "/ris/report/" + this.itemToPrint.RISID + "/2";
      window.open(this.link);
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("StatusID", this.statusID);
      data.append("UserID", this.userID);
      this.axiosCall("/getRISList", "POST", data).then((res) => {
        this.loading = false;
        this.risList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    getUsers() {
      this.axiosCall("/getAllPersonsAccountable", "GET").then((res) => {
        this.userList = [{UserID: 0, name: "All"},...res.data.data];
      });
    },
    selectPrinted(item) {
      let data = new FormData();
      data.append("id", item.RISID);
      data.append("table", 'ris');
      data.append("column", 'RISID');
      data.append("IsPrinted", item.IsPrinted ? 1 : 0);
      this.axiosCall("/setPrinted", "POST", data).then((res) => {
        if(res.data.status){
          let msg = item.IsPrinted ? 'printed.' : 'not printed.';
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = item.RISCODE + " was successfully set to " + msg;
          this.fadeAwayMessage.header = "System Message";
        }
        else{
          item.IsPrinted = !item.IsPrinted;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
      });
    },
  },
};
</script>